.icon-header {
    color: #0496e2
}

.text-container a:hover {
    text-decoration: none;
    color: white
}

// .popover-container {
//     background-color: 3008fd9;
// }