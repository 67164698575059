#login-form {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  color: $appPrimary;
  width: 90%;

  @include media-breakpoint-up(md) {
    width: 600px;
  }

  .section-title {
    font-size: 3em;
    margin-bottom: 0.5em;
		text-align: center;
  }

  #form-container {
    border: 1px solid $appPrimary;
    border-radius: 10px;
    background: white;
    margin: 0 auto;
    padding: 20px 0px 10px;
  }

  .form-header {
    width: 100px;
    height: 100px;
    margin: auto;
    border: 1px solid $appPrimary;;
    border-radius: 50%;
    position: relative;
    top: 20px;
    background: $appPrimary;
    z-index: -1;
    text-align: center;

    .icon {
      color: white;
      top: 20%;
      font-size: 4em;
    }
  }

  .input-group-text.oi-person {
    height: 38px;
    margin-top: -1px;
    padding-top: 9px;
    width: 46px;
  }

  .submit-container {
    text-align: right;
    padding: 0;
  }

  .forgot-password-container {
    margin-top: 5px;
    font-size: 1.1em;
    padding-left: 8px;
  }

  .invalid-feedback {
    padding-left: 45px;
    font-size: 1em;
  }
}

.checkbox label {
  user-select: none;
}
