// Bootstrap
@import '../../bootstrap/functions';
@import '../../bootstrap/variables';
@import '../../bootstrap/mixins';
@import '../common/variables';

// LAYOUT
$content-bg: #f5f7fa;
$aside-bg: #fff;
$corporative: #008fd9;
// NAVBAR TOP
$nav-top-bg: #fff;
$nav-top-bg-start: $nav-top-bg;
$nav-top-bg-end: $info;
$nav-header-bg: transparent;
$nav-top-item: $gray-600;
$nav-top-item-active: darken($nav-top-bg, 20%);
// SIDEBAR
$sidebar-bg: $aside-bg;
$sidebar-item-color: $gray-600;
$sidebar-item-color-active: $corporative;
$sidebar-item-bg-active: darken($sidebar-bg, 1%);
$sidebar-icon-color: $gray-600;
$sidebar-icon-color-active: $corporative;
$sidebar-bullet-color: rgba(0, 0, 0, 0.25);
$sidebar-bullet-color-active: $sidebar-icon-color-active;
$sidebar-heading-color: #919da8;
$sidebar-label-color: $sidebar-item-color;
// OFFSIDEBAR
$offsidebar-bg: black;
$offsidebar-border-color: greyscale(darken($offsidebar-bg, 20%));
$offsidebar-color: $body-color;

/* ========================================================================
   Component: layout
 ========================================================================== */

body,
.wrapper .section-container {
  background-color: $content-bg;
}

.wrapper .aside-container {
  background-color: $aside-bg;
}

/* ========================================================================
   Component: top-navbar
 ========================================================================== */

.topnavbar {
  background-color: $nav-top-bg;
  @include media-breakpoint-up(lg) {
    .navbar-nav > .nav-item.show > .nav-link {
      &,
      &:hover,
      &:focus {
        box-shadow: 0 -3px 0 darken($nav-top-bg, 6%) inset;
      }
    }
  }
  .navbar-nav > .nav-item > .navbar-text {
    color: $nav-top-item;
  }
  .navbar-nav > .nav-item > .nav-link,
  .navbar-nav > .nav-item.show > .nav-link {
    color: $nav-top-item;
    &:hover,
    &:focus {
      color: white;
    }
  }
  .dropdown-item,
  .dropdown-item.active,
  .dropdown-item:active {
    background-color: $nav-top-bg;
    color: $sidebar-item-color;
  }

  .fa-bars {
    color: $corporative;
    font-size: 2em;
  }
}

/* ========================================================================
   Component: sidebar
 ========================================================================== */

.sidebar {
  background-color: $sidebar-bg;
  padding-top: 1em;

  .nav-heading {
    color: $sidebar-heading-color;
    visibility: hidden;
    height: 20px;
  }
  a {
    color: $sidebar-item-color;
  }
}

// Items
.sidebar-nav {
  > li {
    > a,
    > .nav-item {
      color: $sidebar-item-color;
      font-size: 1.1em;
      &:focus,
      &:hover {
        color: $sidebar-item-color-active;
      }
      // Item icon
      > em {
        color: $sidebar-icon-color;
        font-size: 1.2em;
      }
    }
    // Active item state
    &.active,
    &.open {
      &,
      > a,
      > .nav-item,
      .sidebar-nav {
        background-color: $sidebar-item-bg-active;
        color: $sidebar-item-color-active;
        font-weight: 600;
      }
      > .nav-item > em,
      > a > em {
        color: $sidebar-icon-color-active;
      }
    }
    &.active {
      border-left-color: $sidebar-item-color-active;
    }
  }
}

.sidebar-subnav {
	background-color: $sidebar-bg;
  > .sidebar-subnav-header {
    color: $sidebar-item-color;
  }
  > li {
    > a,
    > .nav-item {
			color: $sidebar-item-color;
			font-size: 14px;
      &:focus,
      &:hover {
        color: $sidebar-item-color-active;
      }
    }
    &.active {
      > a,
      > .nav-item {
        color: $sidebar-icon-color-active;
        &:after {
          border-color: $sidebar-bullet-color-active;
          background-color: $sidebar-bullet-color-active;
        }
      }
    }
  }
}

/* ========================================================================
   Component: offsidebar
 ========================================================================== */

.offsidebar {
  border-left: 1px solid $offsidebar-border-color;
  background-color: $offsidebar-bg;
  color: $offsidebar-color;
}

.nav-link.dropdown-toggle {
  &.user {
    color: $gray-600 !important;
    font-size: 1.2em;
  }
  &.planet {
    color: $gray-600 !important;
  }
  padding: 0 25px !important;
  cursor: pointer;
}

.topnavbar .dropdown .dropdown-menu {
  margin-top: 5px;
}

.cursor-pointer {
  cursor: pointer;
}

.ball-scale-multiple > div {
  background-color: #5d9cec !important;
}

.mh-200px {
  min-height: 200px;
}

.mb-xs-3 {
  & {
    margin-bottom: 1rem !important;
  }
}

@include media-breakpoint-down(xs) {
  .w-xs-50 {
    width: 50%;
  }
}

a:hover {
  color: $corporative !important;
}

.btnText {
  background: transparent;
  border: none;
  font-weight: light;
  color: #656565;
  margin: 0;
  padding: 0;
  user-select: none;
  cursor: pointer;
  opacity: 0.6;
  margin-right: 1em;

  :hover {
    opacity: 1;
  }
  
}

.error {
  animation: shake 0.2s;
  animation-iteration-count: 1;
}

@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }
  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }
  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }
  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }
  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }
  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }
  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }
  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }
  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }
  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }
  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}

/* ========================================================================
   Component: React Strap Forms Tweaks
 ========================================================================== */
.invalid-feedback {
  top: -5px;
  position: relative;
  text-transform: lowercase;
  font-weight: 400;
}

// Mark for required form inputs
.required-mark {
  color: red;
  opacity: 0.5;
  padding-left: 3px;
  padding-right: 2px;
}

.required-mark::after {
  content: '*';
}

// GENERIC
.statuses-1 {
  color: #72bb53;
}

.statuses-2 {
  color: #fdc941;
}

.statuses-3 {
  color: #ff675d;
}

// BUDGETS
.budget-statuses-1 {
  color: #fdc941;
}

.budget-statuses-2 {
  color: #72bb53;
}

.budget-statuses-3 {
  color: #ff675d;
}

.order-statuses-4 {
  color: #888;
}

// BUDGETS
.order-statuses-1 {
  color: #59BDD3;
}

.order-statuses-2 {
  color: #fdc941;
}

.order-statuses-3 {
  color: #72bb53;
}

.order-statuses-4 {
  color: #ff675d;
}

// Request
.request-statuses-1 {
  color: #fdc941;
}

.request-statuses-2 {
  color: #59BDD3;
}

.request-statuses-3 {
  color: #72bb53;
}

.request-statuses-4 {
  color: #ff675d;
}

// Delivery

.delivery-statuses-1 {
  color: #fdc941;
  
}

.delivery-statuses-2 {
  color: #59BDD3;
  
}

.delivery-statuses-3 {
  color: #72bb53
}

//Dashboard

.dashboard-title {
  color: rgb(0, 32, 96);
}

// only for reference
.status-others {
  color: #c4b38c;
  color: #ed7ffd;
  color: #ff675d;
  color: #ff675d;
  color: #FFA8A8;
  color: #FFF2F2;
}

.edit:hover, .delete:hover, .add:hover, .detail:hover {
  opacity: .5;
}

.edit:hover, .delete:hover, .add:hover, .detail:hover {
  opacity: 1;
}

.feedback-layer {
  position: absolute;
  background: rgba(240, 240, 240, 0.5);
  height: 100%;
  width: 100%;
  z-index: 10;
  display: flex;
  flex-direction: row;
  border-radius: 0.25em;
  justify-content: center;
  align-items: center;
  padding: 2em;
}

.feedback-layer.confirm {
  display: flex;
  flex-direction: column;
  background: rgba(250, 250, 250, 1);
  background: #ecf0f5;
  // background: rgba(255, 103, 93, 1);
  align-items: flex-start;
 
  & > div {
    font-size: 1.1em;
    font-weight: 600;
  }

  & > div:nth-child(2) {
    opacity: 0.8;
    font-size: 1em;
    margin-bottom: .6em;
    font-weight: 400;
  }
}

.feedback-layer.deleted {
  flex-direction: column;
  background: rgba(250, 250, 250, 1);
  background: #ecf0f5;
  // background: rgba(255, 103, 93, 1);
  align-items: flex-start;
  justify-content: center;
  padding-left: 2em;

  & > div:nth-child(1) {
    font-size: 1em;
    color: #ffa8a8;
  }

  & > div:nth-child(3) {
    opacity: 0.8;
    font-size: 1em;
    font-weight: 400;
    text-decoration: line-through;
  }
}

.content-card {
  padding: 1rem;
  border-radius: 5px;
  border: 1px solid #d6d6d6;
  background-color: #ffffff;
  flex-grow: 1;
  position: relative;
  
  & > .content-card-header {
    border-bottom: 1px solid #d6d6d6;
    padding-bottom: 0.5rem;
    margin-bottom: 1rem;
    min-height: 3rem;
  }
  & > .content-card-footer {
    margin-top: 1rem;
    padding-top: 1rem;
    position: relative;
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: -1rem;
      right: -1rem;
      border-top: 1px solid #d6d6d6;
    }
  }
}

.infinite-scroll-component{
  // overflow: hidden !important; 
}

// FOR DEBUGGING SCROLLING
// /* width */
// ::-webkit-scrollbar {
//   width: 20px;
// }

// /* Track */
// ::-webkit-scrollbar-track {
//   box-shadow: inset 0 0 5px grey;
//   border-radius: 10px;
// }

// /* Handle */
// ::-webkit-scrollbar-thumb {
//   background: red;
//   border-radius: 10px;
// }

.btn-link {
  font-weight: 600;
  cursor: pointer;

  :hover {
    font-weight: 600;
    text-decoration: underline !important;
  }
}

//adjust form styles
.form-control{
  height: 38px;
}

.fav-icon {
  position: relative;
  font-size: .93em;
  top: -1px;
  color: #aaa;
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;

  & .selected {
    color: $corporative;
  }

  & > i {
    font-size: 1.2em;
  }

  & > i:hover {
    color: $corporative;
  }
}

.delete-icon {
  font-size: 1.2em;
  color: #656565;
  max-width: 1em;
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;

  & > i {
    font-size: 1.2em;
  }

  & > i:hover {
    color: #FF5347;
  }
}

.page-selector-modal {
  // max-width: 70%;
  // max-height: 90vh;
  overflow: auto;
  // min-height: 40%;

  .modal-body{
    padding: 0;
    min-height: 250px;
  }
}

.full-page-modal {
  max-width: 95%;
  max-height: 90vh;
  overflow: auto;
  height: 90%;

  .modal-content {
    height: 100%;
  }

  .modal-body{
    padding: 1em;
    min-height: 250px;
  } 
}

.toolbar-icon {
  display: 'inline-block';
  margin-left: .7em;
  cursor: pointer;

  & i {
    color: #656565;
  }

  & i.clone:hover, i.fa-exchange-alt:hover {
    color: #008fd9;
  }
}

// Delete icon in text filter (entity listings
.filter-inputs .input-group .fa-times:hover {
  cursor: pointer;
}

form {
  width: 100%;
}

div {
  // white-space: nowrap;
  white-space: normal;
}

.bb-grey {
    border-bottom: 1px solid $gray-light;
}
.bb-black {
    border-bottom: 1px solid $gray-dark;
}