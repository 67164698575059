#header {
    .brand-logo {
        color: white;
        font-size: 1.15em;
    }
    .nav-link {
        color: white;
    }

}
    .text-toolip:hover {
        text-decoration: none !important;
        color: white !important;
    }