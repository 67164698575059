.dasboard {
    .spinner {
        margin-top: 5rem;
    }
    
    .content-wrapper {
        border-top: none;
    }

    .dashboard-item-date {
        color: $gray-middle;
    }

    .dashboard-item-type {
        text-decoration: underline;
    }

    .dasboard-item-content {
        max-height: 400px;
        overflow-y: auto;
        // background-color: $white;        
        border-top: none;
        // @media (min-width: 992px) {
        //     max-width: 35vw;
        // }
    }

    .dashboard-content {
        margin-left: -20px;
        margin-right: -20px;
    }
    
    ::-webkit-scrollbar {
        width: 10px;
    }

    ::-webkit-scrollbar-track {
        background: lightgray;  /* color of the tracking area */
    }

    ::-webkit-scrollbar-thumb {
        background-color: grey;    /* color of the scroll thumb */
    }

    .dashboard-item-product-available {
        color: $green;
    }
    .dashboard-item-product-not-available {
        color: $danger;
        margin-left: 10px;
        margin-right: 10px;
    }

    .stock-container {
        display: flex;
        @include media-breakpoint-down(md){
            display: flex;
            flex-direction: column;
            width: 100%;
        }   
    }

    .request-container {
        @include media-breakpoint-down(md){
            width: 100% !important;
            padding-bottom: 10px;
            margin-left: 0;
        }  
    }

    .dashboard-containers {
        padding-bottom: 10px;
    }
 
}
